import React, { useEffect } from "react";
import { Seo } from "../../components/seo";
import Layout from "../../hoc/Layout/Layout";

import PolicyContent from "../../components/PolicyContent/PolicyContent";
import { navigate } from "gatsby";

// markup
const IndexPage = () => {

	useEffect(() => {
		const city = localStorage.getItem('city');
		if (city !== 'linkoping') {
			navigate('/integritets-och-cookiespolicy')
		}
	}, []);

	return (
		<Layout>
			<Seo title="Integritetspolicy - Putso" />
			<PolicyContent />
		</Layout>
	);
};

export default IndexPage;
